/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/swiper/swiper-bundle.css";
@import "src/styles_primitives.scss";
@import "./app/_utils/_material-import/custom-material.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.fixed-top {
  z-index: 101;
}

.dropdown-menu {
  --bs-dropdown-zindex: 10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//#region Menu, Cabeceras, PosicionesGlobales
.menu-mobile-header {
  height: map-get($navbar-height, default);

  @media (max-width: map-get($responsive-breakpoints, xl)) {
    height: map-get($navbar-height, tablet);
  }

  @media (max-width: map-get($responsive-breakpoints, md)) {
    height: map-get($navbar-height, mobile);
  }
}

.menu-mobile-height {
  height: calc(100% - map-get($navbar-height, default));

  @media (max-width: map-get($responsive-breakpoints, xl)) {
    height: calc(100% - map-get($navbar-height, tablet));
  }

  @media (max-width: map-get($responsive-breakpoints, md)) {
    height: calc(100% - map-get($navbar-height, mobile));
  }
}

.margin-header {
  margin-top: map-get($navbar-height, default);

  @media (max-width: map-get($responsive-breakpoints, xl)) {
    margin-top: map-get($navbar-height, tablet);
  }

  @media (max-width: map-get($responsive-breakpoints, md)) {
    margin-top: map-get($navbar-height, mobile);
  }

  &.margin-logged {
    margin-top: map-get($navbar-height, default) + map-get($navbar-height, logged);

    @media (max-width: map-get($responsive-breakpoints, xl)) {
      margin-top: map-get($navbar-height, tablet) + map-get($navbar-height, logged);
    }

    @media (max-width: map-get($responsive-breakpoints, md)) {
      margin-top: map-get($navbar-height, mobile) + map-get($navbar-height, logged);
    }
  }
}

.margin-header-plataforma {
  margin-top: map-get($navbar-plataforma-erronea-height, default);

  @media (max-width: map-get($responsive-breakpoints, xl)) {
    margin-top: map-get($navbar-plataforma-erronea-height, tablet);
  }

  @media (max-width: map-get($responsive-breakpoints, md)) {
    margin-top: map-get($navbar-plataforma-erronea-height, mobile);
  }
}

.top-header-perfil {
  top: map-get($navbar-height, default) + map-get($navbar-height, logged);

  @media (max-width: map-get($responsive-breakpoints, lg)) {
    top: map-get($navbar-height, tablet) + map-get($navbar-height, logged);
  }

  @media (max-width: map-get($responsive-breakpoints, md)) {
    top: map-get($navbar-height, mobile) + map-get($navbar-height, logged);
  }
}

.cabecera-height {
  height: 6px;
}

.negative-margin {
  margin-top: -50px;

  @media (max-width: 554px) {
    margin-top: -30px;
  }
}

//Paddings globales para aplicar a partir de ahora en las paginas de feedback 21/02/2025
.paddings-default {
  padding-top: 48px;
  padding-bottom: 100px;

  &.is-plataforma-header {
    padding-top: calc(48px + $platform-header);
  }

  @media(max-width: map-get($responsive-breakpoints, lg)) {
    padding-bottom: 80px;
  }
}

.paddings-volver {
  padding-top: 18px;
  padding-bottom: 100px;

  &.is-plataforma-header {
    padding-top: calc(18px + $platform-header);
  }

  @media(max-width: map-get($responsive-breakpoints, lg)) {
    padding-bottom: 80px;
  }
}

//Margins y Paddings globales

$spacing-map: (
  0: 0rem,
  4: 0.25rem,
  8: 0.5rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  24: 1.5rem,
  32: 2rem,
  40: 2.5rem,
  48: 3rem,
  56: 3.5rem,
  80: 5rem,
  100: 6.25rem,
);

@each $class, $space in $spacing-map {

  // Clases generales
  .s-m-#{$class} {
    margin: $space !important;
  }

  .s-mx-#{$class} {
    margin-left: $space !important;
    margin-right: $space !important;
  }

  .s-my-#{$class} {
    margin-top: $space !important;
    margin-bottom: $space !important;
  }

  .s-mt-#{$class} {
    margin-top: $space !important;
  }

  .s-mb-#{$class} {
    margin-bottom: $space !important;
  }

  .s-p-#{$class} {
    padding: $space !important;
  }

  .s-px-#{$class} {
    padding-left: $space !important;
    padding-right: $space !important;
  }

  .s-py-#{$class} {
    padding-top: $space !important;
    padding-bottom: $space !important;
  }

  .s-pt-#{$class} {
    padding-top: $space !important;
  }

  .s-pb-#{$class} {
    padding-bottom: $space !important;
  }

  // Generando clases responsivas
  @each $breakpoint, $value in $responsive-breakpoints {
    @media (min-width: $value) {
      .s-m-#{$breakpoint}-#{$class} {
        margin: $space !important;
      }

      .s-my-#{$breakpoint}-#{$class} {
        margin-top: $space !important;
        margin-bottom: $space !important;
      }

      .s-mt-#{$breakpoint}-#{$class} {
        margin-top: $space !important;
      }

      .s-mb-#{$breakpoint}-#{$class} {
        margin-bottom: $space !important;
      }

      .s-p-#{$breakpoint}-#{$class} {
        padding: $space !important;
      }

      .s-py-#{$breakpoint}-#{$class} {
        padding-top: $space !important;
        padding-bottom: $space !important;
      }

      .s-pt-#{$breakpoint}-#{$class} {
        padding-top: $space !important;
      }

      .s-pb-#{$breakpoint}-#{$class} {
        padding-bottom: $space !important;
      }
    }
  }
}

.ps-xs-0 {
  @media (max-width: map-get($responsive-breakpoints, sm)) {
    padding-left: 0px;
  }
}

.pe-xs-0 {
  @media (max-width: map-get($responsive-breakpoints, sm)) {
    padding-right: 0px;
  }
}

//#endregion

//#region Width
@each $class, $breakpoint in $responsive-breakpoints {
  .w-#{$class}-auto {
    @media(min-width: $breakpoint) {
      width: auto !important;
    }
  }

  .w-#{$class}-100 {
    @media(min-width: $breakpoint) {
      width: 100% !important;
    }
  }
}

//#endregion

// #region Typography
.mat-typography {
  font-family: map-get($font-family, secondary);
  font-weight: map-get($font-weight, regular);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: map-get($font-weight, bold);
    font-family: map-get($font-family, primary);
    margin: 0;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    color: $primary-500;
  }

  // TODO: Revisar si vienen de bootstrap ya el pointer
  .pointer {
    cursor: pointer;
  }

  h1 {
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 2px;

    //Tablet
    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 24px;
      line-height: 32px;
    }

    //Mobile
    @media screen and (max-width: map-get($responsive-breakpoints, md)) {
      font-size: 22px;
      line-height: 32px;
    }

    &.h1-display {
      font-size: 40px;
      line-height: 54px;
      letter-spacing: 1.3px;

      //Tablet
      @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
        font-size: 34px;
        line-height: 46px;
      }

      //Mobile
      @media screen and (max-width: map-get($responsive-breakpoints, md)) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;

    &.h2-semibold {
      font-weight: map-get($font-weight, semibold);
    }

    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 20px;
      line-height: 28px;
    }

    @media screen and (max-width: map-get($responsive-breakpoints, md)) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;

    &.h3-semibold {
      font-weight: map-get($font-weight, semibold);
    }

    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 18px;
      line-height: 26px;
    }

    @media screen and (max-width: map-get($responsive-breakpoints, md)) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.06em;

    &.h4-medium {
      font-weight: map-get($font-weight, medium);
    }

    //Tablet y mobile (mismo tamaño)
    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  h5 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.06em;

    &.h5-medium {
      font-weight: map-get($font-weight, medium);
    }

    //Tablet y mobile (mismo tamaño)
    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;

    &.h6-medium {
      font-weight: map-get($font-weight, medium);
    }

    &.h6-regular {
      font-weight: map-get($font-weight, regular);
    }

    &.h6-semibold {
      font-weight: map-get($font-weight, semibold);
    }

    //Tablet y mobile (mismo tamaño)
    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  // Font Family and Weight
  .tab-ancla,
  .tab-ancla2,
  .tabs,
  .tabs2 {
    font-family: map-get($font-family, primary);
  }

  .p-display,
  .p-display-bold,
  .p1-bold,
  .p1-medium,
  .p1-semibold,
  .p1-semiboldit,
  .p1-regular,
  .p2-bold,
  .p2-medium,
  .p2-regular,
  .p3-semibold,
  .p3-medium,
  .p3-regular,
  .p3-light,
  a.link1-bold,
  a.link1-semibold,
  a.link2-bold,
  a.link2-semibold,
  a.link2-medium,
  a.link3-bold,
  a.link3-semibold,
  .p1-cifras,
  .p2-cifras,
  .p2-cifras-bold,
  .all-menu {
    font-family: map-get($font-family, secondary);
  }

  .p1-third-regular,
  .p2-third-bold,
  .p2-third-regular,
  .p3-third-regular {
    font-family: map-get($font-family, third);
  }

  .p-display-bold,
  .p1-bold,
  .p2-bold,
  a.link1-bold,
  a.link2-bold,
  a.link3-bold,
  button.link3-bold,
  .p2-third-bold {
    font-weight: map-get($font-weight, bold);
  }

  .p1-semibold,
  .p3-semibold,
  a.link1-semibold,
  a.link2-semibold,
  a.link3-semibold,
  .tab-ancla,
  .tabs {
    font-weight: map-get($font-weight, semibold);
  }

  .p1-semiboldit {
    font-weight: map-get($font-weight, semibold);
    font-style: italic;
  }

  .p-display,
  .p1-medium,
  .p2-medium,
  .p3-medium,
  a.link2-medium,
  .all-menu,
  .tab-ancla2 {
    font-weight: map-get($font-weight, medium);
  }

  .p1-regular,
  .p2-regular,
  .p3-regular,
  .p1-third-regular,
  .p2-third-regular,
  .p3-third-regular,
  .tabs2 {
    font-weight: map-get($font-weight, regular);
  }

  .p3-light {
    font-weight: map-get($font-weight, light);
  }

  // Text Styles
  .p-display,
  .p-display-bold {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }

  .p1-bold,
  .p1-medium,
  .p1-semibold,
  .p1-semiboldit,
  .p1-regular {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .p2-bold,
  .p2-medium,
  .p2-regular {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  .p3-semibold,
  .p3-medium,
  .p3-regular,
  .p3-light {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }

  a.link1-bold,
  a.link1-semibold,
  button.link1-bold,
  button.link1-semibold {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-decoration: underline;
  }

  a.link2-bold,
  a.link2-semibold,
  a.link2-medium {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-decoration: underline;
  }

  a.link3-bold,
  a.link3-semibold,
  button.link3-bold {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-decoration: underline;
  }

  .p1-cifras {
    font-size: 32px;
    font-weight: map-get($font-weight, bold);
    line-height: 40px;
    letter-spacing: 0.5px;

    //Tablet
    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 28px;
      line-height: 38px;
    }

    //Mobile
    @media screen and (max-width: map-get($responsive-breakpoints, md)) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .p2-cifras {
    font-size: 24px;
    font-weight: map-get($font-weight, medium);
    line-height: 34px;
    letter-spacing: 0.5px;

    //Tablet
    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 22px;
      line-height: 32px;
    }

    //Mobile
    @media screen and (max-width: map-get($responsive-breakpoints, md)) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .p2-cifras-bold {
    font-size: 24px;
    font-weight: map-get($font-weight, bold);
    line-height: 34px;
    letter-spacing: 0.5px;

    //Tablet
    @media screen and (max-width: map-get($responsive-breakpoints, xl)) {
      font-size: 22px;
      line-height: 32px;
    }

    //Mobile
    @media screen and (max-width: map-get($responsive-breakpoints, md)) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .p1-third-regular {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }

  .p2-third-bold,
  .p2-third-regular {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  .p3-third-regular {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  .all-menu {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .tab-ancla {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }

  .tab-ancla2 {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
  }

  .tabs,
  .tabs2 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.font-primary {
  font-family: map-get($font-family, primary) !important;
}

.font-secondary {
  font-family: map-get($font-family, secondary) !important;
}

.font-third {
  font-family: map-get($font-family, third) !important;
}

@for $i from 10 through 100 {
  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }

  @if($i >=24) {
    $i: $i + 2;
  }
}

@for $i from 100 through 900 {
  .font-weight-#{$i} {
    font-weight: #{$i} !important;
  }

  $i: $i + 100;
}

@for $i from 10 through 100 {
  .line-height-#{$i} {
    line-height: #{$i}px !important;
  }

  @if($i >=24) {
    $i: $i + 2;
  }
}

@for $i from 0 through 9 {
  $spacing: $i * 0.01;

  .letter-spacing-#{$i} {
    letter-spacing: #{$spacing}px !important;
  }
}


@for $i from 10 through 200 {
  .circle-container-#{$i} {
    border-radius: 400px;
    height: #{$i}px !important;
    width: #{$i}px !important;

    @if($i >=100) {
      $i: $i + 5;
    }
  }
}


// # endregion

// #region TextColors 
$text-color-map: (
  primary-50: $primary-50,
  primary-75: $primary-75,
  primary-100: $primary-100,
  primary-200: $primary-200,
  primary-300: $primary-300,
  primary-400: $primary-400,
  primary-500: $primary-500,
  primary-800: $primary-800,
  primary-900: $primary-900,
  neutral-0: $neutral-0,
  neutral-100: $neutral-100,
  neutral-400: $neutral-400,
  neutral-500: $neutral-500,
  neutral-800: $neutral-800,
  neutral-900: $neutral-900,
  neutral-1000: $neutral-1000,
  secondary-venture-200: $secondary-venture-200,
  secondary-venture-400: $secondary-venture-400,
  secondary-venture-500: $secondary-venture-500,
  secondary-venture-600: $secondary-venture-600,
  secondary-venture-700: $secondary-venture-700,
  secondary-venture-800: $secondary-venture-800,
  secondary-funds-200: $secondary-funds-200,
  secondary-funds-400: $secondary-funds-400,
  secondary-funds-300: $secondary-funds-300,
  secondary-funds-500: $secondary-funds-500,
  secondary-funds-600: $secondary-funds-600,
  secondary-funds-700: $secondary-funds-700,
  secondary-funds-800: $secondary-funds-800,
  secondary-factoring-200: $secondary-factoring-200,
  secondary-factoring-400: $secondary-factoring-400,
  secondary-factoring-500: $secondary-factoring-500,
  secondary-factoring-600: $secondary-factoring-600,
  secondary-factoring-700: $secondary-factoring-700,
  secondary-factoring-800: $secondary-factoring-800,
  secondary-estate-200: $secondary-estate-200,
  secondary-estate-300: $secondary-estate-300,
  secondary-estate-400: $secondary-estate-400,
  secondary-estate-500: $secondary-estate-500,
  secondary-creative-200: $secondary-creative-200,
  secondary-creative-400: $secondary-creative-400,
  secondary-creative-500: $secondary-creative-500,
  secondary-creative-600: $secondary-creative-600,
  semantic-info-300: $semantic-info-300,
  semantic-info-400: $semantic-info-400,
  semantic-info-500: $semantic-info-500,
  semantic-info-800: $semantic-info-800,
  semantic-info-900: $semantic-info-900,
  semantic-warning-400: $semantic-warning-400,
  semantic-warning-500: $semantic-warning-500,
  semantic-warning-800: $semantic-warning-800,
  semantic-success-100: $semantic-success-100,
  semantic-success-300: $semantic-success-300,
  semantic-success-400: $semantic-success-400,
  semantic-success-500: $semantic-success-500,
  semantic-success-800: $semantic-success-800,
  semantic-error-100: $semantic-error-100,
  semantic-error-200: $semantic-error-200,
  semantic-error-400: $semantic-error-400,
  semantic-error-500: $semantic-error-500,
  semantic-error-700: $semantic-error-700,
  semantic-error-900: $semantic-error-900,
);

@each $class, $color in $text-color-map {
  .#{$class} {
    color: $color;
  }
}

.link-my-investor {
  color: #1C6FC9;
}

//#endregion

// #region Backgrounds
$bg-color-map: (
  primary-50: $primary-50,
  primary-75: $primary-75,
  primary-100: $primary-100,
  primary-200: $primary-200,
  primary-300: $primary-300,
  primary-400: $primary-400,
  primary-500: $primary-500,
  primary-800: $primary-800,
  primary-900: $primary-900,
  neutral-0: $neutral-0,
  neutral-100: $neutral-100,
  neutral-300: $neutral-300,
  neutral-500: $neutral-500,
  neutral-1000: $neutral-1000,
  secondary-venture-100: $secondary-venture-100,
  secondary-venture-200: $secondary-venture-200,
  secondary-venture-300: $secondary-venture-300,
  secondary-venture-400: $secondary-venture-400,
  secondary-venture-500: $secondary-venture-500,
  secondary-venture-600: $secondary-venture-600,
  secondary-venture-700: $secondary-venture-700,
  secondary-venture-800: $secondary-venture-800,
  secondary-factoring-100: $secondary-factoring-100,
  secondary-factoring-200: $secondary-factoring-200,
  secondary-factoring-300: $secondary-factoring-300,
  secondary-factoring-400: $secondary-factoring-400,
  secondary-factoring-500: $secondary-factoring-500,
  secondary-factoring-600: $secondary-factoring-600,
  secondary-factoring-700: $secondary-factoring-700,
  secondary-factoring-800: $secondary-factoring-800,
  secondary-estate-50: $secondary-estate-50,
  secondary-estate-100: $secondary-estate-100,
  secondary-estate-200: $secondary-estate-200,
  secondary-estate-300: $secondary-estate-300,
  secondary-estate-400: $secondary-estate-400,
  secondary-estate-500: $secondary-estate-500,
  secondary-funds-100: $secondary-funds-100,
  secondary-funds-200: $secondary-funds-200,
  secondary-funds-300: $secondary-funds-300,
  secondary-funds-400: $secondary-funds-400,
  secondary-funds-500: $secondary-funds-500,
  secondary-funds-600: $secondary-funds-600,
  secondary-funds-700: $secondary-funds-700,
  secondary-funds-800: $secondary-funds-800,
  secondary-creative-100: $secondary-creative-100,
  secondary-creative-200: $secondary-creative-200,
  secondary-creative-300: $secondary-creative-300,
  secondary-creative-400: $secondary-creative-400,
  secondary-creative-500: $secondary-creative-500,
  secondary-creative-600: $secondary-creative-600,
  secondary-creative-700: $secondary-creative-700,
  secondary-creative-800: $secondary-creative-800,
  semantic-info-50: $semantic-info-50,
  semantic-info-100: $semantic-info-100,
  semantic-info-300: $semantic-info-300,
  semantic-info-400: $semantic-info-400,
  semantic-info-800: $semantic-info-800,
  semantic-warning-50: $semantic-warning-50,
  semantic-warning-100: $semantic-warning-100,
  semantic-warning-300: $semantic-warning-300,
  semantic-warning-400: $semantic-warning-400,
  semantic-success-100: $semantic-success-100,
  semantic-success-300: $semantic-success-300,
  semantic-success-400: $semantic-success-400,
  semantic-success-500: $semantic-success-500,
  semantic-error-50: $semantic-error-50,
  semantic-error-100: $semantic-error-100,
  semantic-error-200: $semantic-error-200,
  semantic-error-400: $semantic-error-400,
  semantic-error-500: $semantic-error-500,
);

@each $class, $color in $bg-color-map {
  .bg-#{$class} {
    //TODO: para quitar este important hace falta como minimo quitar el background de los shadow
    background-color: $color !important;
  }
}

// #endregion

// #region Borders
.border-none,
.border-0 {
  border: none !important;
}

$border-radius-map: (
  12: 12px,
  15: 15px,
  18: 18px,
  20: 20px,
  25: 25px,
  50: 50px,
  100: 100px,
  150: 150px,
  200: 200px,
);

@each $class, $radius in $border-radius-map {
  .border-radius-#{$class} {
    border-radius: $radius !important;
  }
}

@for $i from 0 through 10 {
  .border-#{$i} {
    border: #{$i}px solid;
  }

  .border-y-#{$i} {
    border-top: #{$i}px solid;
    border-bottom: #{$i}px solid;
  }

  .border-x-#{$i} {
    border-left: #{$i}px solid;
    border-right: #{$i}px solid;
  }

  .border-top-#{$i} {
    border-top: #{$i}px solid;
  }

  .border-bottom-#{$i} {
    border-bottom: #{$i}px solid;
  }

  .border-left-#{$i} {
    border-left: #{$i}px solid;
  }

  .border-right-#{$i} {
    border-right: #{$i}px solid;
  }

  //TODO: Revisar si se puede hacer sin el important
  .border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }

  .border-radius-top-#{$i} {
    border-top-left-radius: #{$i}px !important;
    border-top-right-radius: #{$i}px !important;
  }

  .border-radius-bottom-#{$i} {
    border-bottom-left-radius: #{$i}px !important;
    border-bottom-right-radius: #{$i}px !important;
  }

  .border-radius-left-#{$i} {
    border-top-left-radius: #{$i}px !important;
    border-bottom-left-radius: #{$i}px !important;
  }

  .border-radius-right-#{$i} {
    border-top-right-radius: #{$i}px !important;
    border-bottom-right-radius: #{$i}px !important;
  }

  .border-radius-top-left-#{$i} {
    border-top-left-radius: #{$i}px !important;
  }

  .border-radius-top-right-#{$i} {
    border-top-right-radius: #{$i}px !important;
  }

  .border-radius-bottom-left-#{$i} {
    border-bottom-left-radius: #{$i}px !important;
  }

  .border-radius-bottom-right-#{$i} {
    border-bottom-right-radius: #{$i}px !important;
  }
}

$border-color-map: (
  transparente: 'transparent',
  tab-inactive: rgba(143, 156, 173, 0.25),
  primary: $primary-900,
  primary-50: $primary-50,
  primary-100: $primary-100,
  primary-300: $primary-300,
  primary-400: $primary-400,
  primary-500: $primary-500,
  primary-800: $primary-800,
  primary-900: $primary-900,
  neutral-0: $neutral-0,
  neutral-400: $neutral-400,
  neutral-500: $neutral-500,
  neutral-900: $neutral-900,
  secondary-venture-500: $secondary-venture-500,
  secondary-venture-800: $secondary-venture-800,
  secondary-factoring-300: $secondary-factoring-300,
  secondary-factoring-500: $secondary-factoring-500,
  secondary-factoring-600: $secondary-factoring-600,
  secondary-factoring-800: $secondary-factoring-800,
  secondary-estate-500: $secondary-estate-500,
  secondary-estate-300: $secondary-estate-300,
  secondary-funds-500: $secondary-funds-500,
  secondary-funds-600: $secondary-funds-600,
  secondary-funds-700: $secondary-funds-700,
  secondary-funds-800: $secondary-funds-800,
  secondary-creative-400: $secondary-creative-400,
  secondary-creative-500: $secondary-creative-500,
  semantic-error-200: $semantic-error-200,
  semantic-error-400: $semantic-error-400,
  semantic-error-500: $semantic-error-500,
  semantic-success-300: $semantic-success-300,
  semantic-success-400: $semantic-success-400,
  semantic-success-500: $semantic-success-500,
  semantic-warning-100: $semantic-warning-100,
  semantic-warning-300: $semantic-warning-300,
  semantic-warning-400: $semantic-warning-400,
  semantic-warning-500: $semantic-warning-500,
  semantic-info-100: $semantic-info-100,
  semantic-info-300: $semantic-info-300,
  semantic-info-400: $semantic-info-400,
  semantic-info-500: $semantic-info-500,
  semantic-info-800: $semantic-info-800,
);

@each $class, $color in $border-color-map {
  .border-#{$class} {
    border-color: $color !important;
  }
}

//#endregion


//#region box-shadow
.shadow-4,
.shadow-8 {
  //TODO: El background no debería meterse aquí nunca, debería emplearse otra clase en el html (bg-primary-50)
  background: $primary-50;
}

.shadow-4 {
  box-shadow: 0px 1px 4px 0px rgba(2, 24, 43, 0.15);
}

.shadow-8 {
  box-shadow: 0px 2px 8px 0px rgba(2, 24, 43, 0.15);
}

.shadow-24 {
  box-shadow: 0px 8px 24px 0px rgba(2, 24, 43, 0.15);
}

.shadow-28 {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.shadow-32 {
  box-shadow: 0px 16px 32px 0px rgba(2, 24, 43, 0.15);
}

// #endregion

//#region text-shadow
.text-shadow-28 {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

// #endregion

//#region buttons
button {
  border: none;
}

button.btn,
a.btn,
.btn {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-family: map-get($font-family, primary);
  font-weight: map-get($font-weight, bold);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06em;
  padding: 10px 30px;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;

  .material-symbols-outlined {
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
  }

  .btn-loading-width {
    height: 0px;
    opacity: 0;
  }

  &:hover,
  &:hover .material-symbols-outlined {
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
  }

  @media(max-width: map-get($responsive-breakpoints, md)) {
    font-weight: map-get($font-weight, semibold);
    letter-spacing: 0.04em;
  }

  &.btn-primary,
  &.btn-elevated,
  &.btn-outline,
  &.btn-secondary,
  &.btn-filtro {
    border: 1px solid;
  }

  &.btn-primary:disabled,
  &.btn-elevated:disabled,
  &.btn-outline:disabled,
  &.btn-secondary:disabled &.btn-filtro:disabled {
    font-weight: map-get($font-weight, semibold);
  }

  //Primary
  &.btn-primary {
    border-color: $primary-900;
    background-color: $primary-900;
    color: $primary-50;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-50;
    }
  }

  &.btn-primary:disabled {
    border: 1.5px solid;
    border-color: $neutral-100;
    background-color: $neutral-0;
    color: $neutral-100;
    opacity: 1;

    &:not(.btn-color) .material-symbols-outlined {
      color: $neutral-100;
    }
  }

  &.btn-primary:hover,
  &.btn-primary:focus {
    border-color: $primary-900;
    background-color: $primary-50;
    color: $primary-900;

    &.hover-primary-300 {
      background-color: $primary-300;
    }

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-primary:focus {
    border: 1.5px solid;
  }

  &.btn-primary:active,
  &.btn-primary.btn-active {
    border-color: $primary-200;
    background-color: $primary-200;
    color: $primary-900;
  }

  //Elevated
  &.btn-elevated {
    color: $primary-50;
    background-color: $primary-900;
    border-color: $primary-900;
    box-shadow: 0px 2px 3px 1px #00000026;
    box-shadow: 0px 2px 4px 0px #0000004D;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-50;
    }
  }

  &.btn-elevated:disabled {
    border: 1.5px solid;
    border-color: $neutral-100;
    background-color: $neutral-0;
    color: $neutral-100;
    opacity: 1;

    &:not(.btn-color) .material-symbols-outlined {
      color: $neutral-100;
    }
  }

  &.btn-elevated:hover,
  &.btn-elevated:focus {
    border-color: $primary-50;
    background-color: $primary-50;
    color: $primary-900;

    &.hover-primary-300 {
      background-color: $primary-300;
    }

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-elevated:focus {
    border: 1.5px solid;
  }

  &.btn-elevated:active,
  &.btn-elevated.btn-active {
    border-color: $primary-200;
    background-color: $primary-200;
    color: $primary-900;
  }

  //Outline
  &.btn-outline {
    color: $primary-900;
    background-color: transparent;
    border-color: $primary-900;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-outline:disabled {
    border: 1.5px solid;
    border-color: $neutral-100;
    color: $neutral-100;
    opacity: 1;

    &:not(.btn-color) .material-symbols-outlined {
      color: $neutral-100;
    }
  }

  &.btn-outline:hover,
  &.btn-outline:focus {
    border-color: $primary-900;
    background-color: $primary-300;
    color: $primary-900;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-outline:focus {
    border: 1.5px solid;
  }

  &.btn-outline:active,
  &.btn-outline.btn-active {
    border-color: $primary-900;
    background-color: $primary-200;
    color: $primary-900;
  }

  //Secondary
  &.btn-secondary {
    color: $primary-900;
    background-color: $primary-50;
    border-color: $primary-900;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-secondary:disabled {
    border: 1.5px solid;
    border-color: $neutral-100;
    background-color: $neutral-0;
    color: $neutral-100;
    opacity: 1;

    &:not(.btn-color) .material-symbols-outlined {
      color: $neutral-100;
    }
  }

  &.btn-secondary:hover,
  &.btn-secondary:focus 
  {
    border-color: $primary-900;
    background-color: $primary-300;
    color: $primary-900;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-secondary:focus {
    border: 1.5px solid;
  }

  &.btn-secondary:active,
  &.btn-secondary.btn-active {
    border-color: $primary-900;
    background-color: $primary-200;
    color: $primary-900;
  }

  //Filtro 
  //TODO: @LSegovia -> Para este sería recomendable tener un componente común, con los estilos no será suficiente
  &.btn-filtro {
    color: $primary-900;
    background-color: $primary-50;
    border-color: $primary-900;
    padding: 10px 20px;
    min-width: 0px;
    transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -ms-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;

    @media (max-width: map-get($responsive-breakpoints, sm)) {
      padding: 8px;
    }

    &.fac-btn-filtro {
      @media (max-width: map-get($responsive-breakpoints, md)) {
        padding: 8px;
      }
    }

    .material-symbols-outlined {
      color: $primary-900;
    }

    .icon-filter {
      $size: 24px;
      font-size: $size;
      height: $size;
      width: $size;

      @media (min-width: map-get($responsive-breakpoints, sm)) {
        margin-right: .5rem;
      }
    }

    .icon-arrow {
      $size: 20px;
      font-size: $size;
      height: $size;
      width: $size;

      @media (min-width: map-get($responsive-breakpoints, sm)) {
        margin-left: 1rem;
      }
    }
  }

  &.btn-filtro:disabled {
    border: 1.5px solid;
    border-color: $neutral-100;
    background-color: $neutral-0;
    color: $neutral-100;
    opacity: 1;

    .material-symbols-outlined {
      color: $neutral-100;
    }
  }

  &.btn-filtro:focus {
    color: $primary-900;
    background-color: $primary-50;
    border-color: $primary-900;

    .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-filtro:hover,
  &.btn-filtro:active,
  &.btn-filtro.btn-active,
  &.btn-filtro[aria-expanded="true"] {
    color: $primary-900;
    background-color: $primary-50;
    border-color: $primary-900;
    border: 2px solid;
    transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -ms-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;

    :not(.material-symbols-outlined) {
      font-weight: map-get($font-weight, bold);
      ;
    }
  }

  //Text
  &.btn-text {
    color: $primary-900;
    background-color: transparent;
    text-decoration: underline;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-900;
    }
  }

  &.btn-text:disabled {
    color: $neutral-300;
    text-decoration: none;
    opacity: 1;

    &:not(.btn-color) .material-symbols-outlined {
      color: $neutral-300;
    }
  }

  &.btn-text:hover {
    color: $primary-500;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-500;
    }
  }

  &.btn-text:focus {
    color: $primary-800;

    &:not(.btn-color) .material-symbols-outlined {
      color: $primary-800;
    }
  }

  &.btn-text:active,
  &.btn-text.btn-active {
    background-color: $neutral-100;
    color: $primary-800;
  }
}

//TODO: Revisar si de verdad hace falta este estilo aquí
a.btn-tipo-producto,
.btn-tipo-producto {
  color: $primary-800;
  font-size: 16px;
}

a.btn-tipo-producto.active,
.btn-tipo-producto.active {
  background-color: $primary-900;
  color: $neutral-0;
}

//#endregion

// #region separadores (para cambiar color anadir el background: "linea-separadora-X border-primary-X")
@for $i from 1 through 5 {
  .linea-separadora-#{$i} {
    border-bottom: #{$i}px solid;
    min-height: #{$i}px;
  }
}

// #endregion

// #region Swiper
.swiper-slide {
  z-index: 1;
  height: auto !important;
}

.swiper-btn-segofinance,
.swiper-btn-venture,
.swiper-btn-factoring,
.swiper-btn-realestate,
.swiper-btn-funds,
.swiper-btn-creative {
  border-radius: 4rem;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;

  &.swiper-prev,
  &.swiper-next {
    background: $primary-900;
    color: $primary-50;

    .material-symbols-outlined {
      $size: 16px;
      font-size: $size;
      width: $size;
      height: $size;
    }

    &.swiper-button-disabled {
      background: $primary-50;
      color: $primary-900;
    }
  }

  &.swiper-next {
    transform: translate(-5px);

    .material-symbols-outlined {
      padding-left: 1px;
    }
  }

  &.swiper-prev {
    margin-right: 0.75rem;
    transform: translate(5px);

    .material-symbols-outlined {
      padding-left: 3px;
    }
  }
}

.swiper-btn-venture.swiper-prev,
.swiper-btn-venture.swiper-next,
.swiper-btn-factoring.swiper-prev,
.swiper-btn-factoring.swiper-next,
.swiper-btn-funds.swiper-prev,
.swiper-btn-funds.swiper-next,
.swiper-btn-realestate.swiper-prev,
.swiper-btn-realestate.swiper-next {
  color: $primary-50;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .material-symbols-outlined {
    $size: 24px;
    font-size: $size;
    width: $size;
    height: $size;
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.swiper-btn-venture.swiper-prev,
.swiper-btn-venture.swiper-next {
  background: $secondary-venture-500;
}

.swiper-btn-factoring.swiper-prev,
.swiper-btn-factoring.swiper-next {
  background: $secondary-factoring-600;
}

.swiper-btn-funds.swiper-prev,
.swiper-btn-funds.swiper-next {
  background: $secondary-funds-500;
}

.swiper-btn-realestate.swiper-prev,
.swiper-btn-realestate.swiper-next {
  background: $secondary-funds-500;
}

.swiper-btn-creative.swiper-prev,
.swiper-btn-creative.swiper-next {
  background: $secondary-creative-500;
  color: $primary-50;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .material-symbols-outlined {
    $size: 24px;
    font-size: $size;
    width: $size;
    height: $size;
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.swiper-navigation-centered {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 100% !important;
}

.swiper-pagination-bullet {
  background: $primary-300 !important;
  border: 1px solid $primary-300 !important;
  opacity: 1 !important;

  &.swiper-pagination-bullet-active {
    background-color: $primary-300 !important;
    width: 24px;
    height: 8px;
    border-radius: 4px;
    background-color: $primary-300;
    margin: 0 6px;
    transition: all 0.3s ease;
  }
}

.swiper-pagination-portada .swiper-pagination-bullet-active {
  border-color: $primary-50 !important;
}

.swiper-pagination-bullet.pagination-sego-secondary {
  background: $primary-50 !important;
  border: 1.5px solid $primary-900 !important;
  opacity: 1 !important;
  $size: 10px !important;
  width: $size;
  height: $size;

  &.swiper-pagination-bullet-active {
    opacity: 0.7;
  }
}

.margin-bottom-swiper {
  margin-bottom: 17px;
}

.padding-end-section {
  padding-bottom: 100px;

  @media(max-width: map-get($responsive-breakpoints, lg)) {
    padding-bottom: 80px;
  }
}

.padding-paginator {
  padding-bottom: 92px;

  @media(max-width: map-get($responsive-breakpoints, lg)) {
    padding-bottom: 72px;
  }
}

// #endregion

//#region cookies
#ot-sdk-btn-floating.ot-floating-button {
  z-index: 1 !important;
}

#ot-sdk-btn-floating {
  display: none;
}

#onetrust-pc-sdk {
  #ot-pc-title {
    font-size: 24px !important;
    line-height: 36px !important;
  }

  #ot-category-title {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .ot-cat-header {
    font-size: 14px !important;
  }
}

//#endregion

// #region FooterPages
.acordeon-flotante {
  width: 690px;
  top: 85px;
  left: 10px;
  max-width: 320px;

  &.logged {
    top: 120px;
  }

  @media (max-width: map-get($responsive-breakpoints, md)) {
    width: 525px !important;
    top: 75px;

    &.logged {
      top: 110px;
    }
  }
}

#enlaces-pagina {
  &.position-fixed {
    top: 175px;
    max-width: 340px;
  }
}

// #endregion

// #region reserva venture (minificha / invertir mobile)
.custom-titulo-reserva-success {
  font-family: map-get($font-family, secondary) !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  font-size: 18px !important;
}

.custom-subtitulo-reserva-success {
  font-family: map-get($font-family, secondary) !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  font-size: 18px !important;
}

@media (max-width: map-get($responsive-breakpoints, md)) {
  .custom-subtitulo-reserva-success {
    font-family: map-get($font-family, secondary) !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-size: 16px !important;
  }
}

//#endregion

// #translate
@for $i from 2 through 50 {
  .translate-top-#{$i} {
    transform: translateY(-#{$i}px);
  }

  .translate-bottom-#{$i} {
    transform: translateY(#{$i}px);
  }

  .translate-start-#{$i} {
    transform: translateX(-#{$i}px);
  }

  .translate-end-#{$i} {
    transform: translateX(#{$i}px);
  }

  $i: $i + 2;
}

// #endregion

// #rotation
@for $i from 90 through 270 {
  .rotate-#{$i} {
    transform: rotate(#{$i}deg);
  }

  $i: $i + 90;
}

// #endregion

// #region experimentado-tag
.mt-experimentado {
  margin-top: -10px;
}

// #endregion

// #region height 
// Mixin to generate height classes with breakpoints
@mixin generate-height-classes($sizes, $breakpoints) {
  @each $size in $sizes {
    .height-#{$size} {
      height: #{$size}px !important;
    }

    @each $breakpoint, $value in $breakpoints {
      @media (min-width: #{$value}) {
        .height-#{$breakpoint}-#{$size} {
          height: #{$size}px !important;
        }
      }
    }
  }
}

// Generate height classes from 1px to 100px
@for $i from 1 through 100 {
  .height-#{$i} {
    height: #{$i}px !important;
  }
}

// Generate height classes in increments of 50px from 50px to 500px
$sizes: (
);

@for $i from 1 through 10 {
  $size: $i * 50;
  $sizes: append($sizes, $size);
}

@include generate-height-classes($sizes, $responsive-breakpoints);

// #endregion

//#region portada-banner
#banner-portada {

  a.primary-50,
  a.primary-900 {
    transition: transform 0.3s;
  }

  a.primary-50:hover {
    color: #F0F2F4;
  }

  a.primary-900:hover {
    color: #02182b;
  }

  a.primary-50:hover,
  a.primary-900:hover {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transform: scale(1.05);
  }

  a.link-shadow {
    text-shadow: 0px 0px 1px #02182B;
  }

  .swiper-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 600px;
  }

  .portada-title {
    font-family: map-get($font-family, primary) !important;
    font-weight: 700 !important;
    font-size: 64px !important;
    line-height: 55px !important;
    letter-spacing: 0.04em !important;

    @media(max-width: 1220px) {
      font-size: 50px !important;
      line-height: 64px !important;
    }

    @media(max-width: map-get($responsive-breakpoints, md)) {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    &.portada-title-linea {
      line-height: 88px !important;

      @media(max-width: 1220px) {
        font-size: 58px !important;
        line-height: 70px !important;
      }

      @media(max-width: map-get($responsive-breakpoints, md)) {
        font-size: 40px !important;
        line-height: 48px !important;
      }
    }
  }
}

// #endregion

// #region listas
.centered-list {
  list-style-position: inside;
  padding-left: 0;
  text-align: center;
}

@each $class, $breakpoint in $responsive-breakpoints {
  .centered-#{$class}-list {
    @media(min-width: $breakpoint) {
      list-style-position: inside;
      padding-left: 0;
      text-align: center;
    }
  }
}

//#endregion

.subir-info-body {
  border-radius: 10px;
  border: 2px #02182B;
  border-style: dotted;
  font-weight: bold;
  line-height: 1;
}

.circulo-small {
  display: inline-block;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

#mis-inversiones-factoring,
#mis-inversiones-list,
#mis-inversiones-list-movil,
#dialog-detalles-operacion {
  .rounded-pill {
    border: 1px solid #637182;
  }

  //#region tipoEstadoOperacion
  .circulo-cobrado {
    background: #3A8A36;
  }

  .circulo-fallido {
    background: #EC6082;
  }

  .circulo-disputa {
    background: #83102D;
  }

  .circulo-gestionando-cobro {
    background: #E19805;
  }

  .circulo-no-llego-fecha-cobro {
    background: #78C8DE;
  }

  .pill-fallido {
    background: #A41438;
    border-color: #A41438 !important;
    color: $neutral-0 !important;
    font-weight: 500;
  }

  //#endregion

  //#region tipoEstadoRonda
  .circulo-aprobado {
    background: #2989A3;
  }

  .circulo-comprobado {
    background: #BDBDBD;
  }

  .circulo-pendiente {
    background: #F9A806;
  }

  .circulo-rechazado {
    background: #424B57;
  }

  .circulo-ronda-abierta {
    background: #3A8A36;
  }

  .circulo-exito {
    background: #89D086;
  }

  .circulo-sin-exito {
    background: #EC6082;
  }

  .pill-rechazado {
    background: #B8C5D6;
    border-color: #B8C5D6 !important;
    color: #424B57 !important;
  }

  .pill-exito {
    background: #3A8A36;
    border-color: #3A8A36 !important;
    color: $neutral-0;
    font-weight: 500;
  }

  .pill-sin-exito {
    color: $neutral-0;
    background-color: #A41438;
    border-color: #A41438 !important;
    font-weight: 500;
  }

  //#endregion
}

.pill-border-my {
  position: relative;
  padding: 1px 10px 3px 10px;
}

.pill-border-my::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  border-radius: 12px;
  background: linear-gradient(180deg, #A838A3 0%, #225FCF 19.9%, #EF9E1D 83.96%, #E52B19 100%) border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

#mis-inversiones-list-movil {

  .pill-exito,
  .pill-cobrado {
    width: 100%;
  }

  .pill-exito {
    margin-bottom: 0.5rem;
  }
}

//#region ngx-chart
.ngx-charts-tooltip-content {
  .tooltip-item-color {
    border: 1px solid $neutral-0 !important;
    border-radius: 4rem !important;
  }
}

[ngx-charts-x-axis-ticks],
[ngx-charts-y-axis-ticks] {
  >g {
    >.tick text {
      font-family: map-get($font-family, secondary);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.07em;
    }
  }
}

[data-ngx-charts-rotate-xAxis="true"] {
  [ngx-charts-x-axis-ticks] {
    >g {
      >.tick text {
        transform: rotate(-25deg);
      }
    }
  }
}

[hideXAxisTicks] {
  [ngx-charts-x-axis-ticks] {
    >g {
      ~g {
        display: none;
      }
    }
  }
}

[hideYAxisTicks] {
  [ngx-charts-y-axis-ticks] {
    >g {
      ~g {
        display: none;
      }
    }
  }
}

//#endregion

// #region animation
.fadein {
  $speed: 1s;
  -webkit-animation: fadein $speed;
  animation: fadein $speed;
}

.fadeinMenu {
  $speed: 0.2s;
  -webkit-animation: fadein $speed;
  animation: fadein $speed;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.flickering {
  animation-name: flickering;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: flickering;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@keyframes flickering {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@-webkit-keyframes flickering {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

//#region isLoading

.padding-is-loading {
  padding-top: 240px;
  padding-bottom: 240px;

  @media(max-width: map-get($responsive-breakpoints, lg)) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

.padding-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}

//#endregion

// #region icon
.icon-area-26 {
  position: relative;
  width: 19px;
  height: 19px;
}

.icon-area-26::before {
  content: "";
  position: absolute;
  top: -3.5px;
  left: -3.5px;
  width: 26px;
  height: 26px;
  background: transparent;
  cursor: pointer;
}

// #endregion